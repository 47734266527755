import React from 'react';

export default function Beneficios() {
  return (
    <section
      className="content-section benefits-section bg-secondary"
      id="beneficios"
    >
      <div className="container-fluid text-white">
        <div className="row">
          <div className="col-12 mb-5 text-center">
            <h2 className="font-size__xl text-primary">
              ¿Qué aporta BigCommerce a tu negocio?
            </h2>          
          </div>
        </div>
        <div className='row'>
          <div className='col-12 col-lg-5 offset-lg-1'>
            <ul className='list-unstyled text-left font-size__m pl-5'>
              <li><i className='fa fa-check text-primary'></i><strong className='text-primary'>Rapidez:</strong> el 90% de los proyectos salen a la luz en menos de 3 meses.</li>
              <li><i className='fa fa-check text-primary'></i><strong className='text-primary'>Flexibilidad:</strong> menos complejidad y mayor capacidad de integración</li>
              <li><i className='fa fa-check text-primary'></i><strong className='text-primary'>Agilidad:</strong> actualizaciones automáticas y mayor rapidez para aplicar cambios</li>
              <li><i className='fa fa-check text-primary'></i><strong className='text-primary'>Personalización:</strong> al ser una plataforma abierta permite añadir todo tipo de desarrollos personalizados</li>
            </ul>
          </div>
          <div className='col-12 col-lg-5'>
            <ul className='list-unstyled text-left font-size__m pl-5'>
              <li><i className='fa fa-check text-primary'></i><strong className='text-primary'>Control del gasto:</strong> una cuota fija que incluye hosting, plataforma y seguros</li>
              <li><i className='fa fa-check text-primary'></i><strong className='text-primary'>Rentabilidad:</strong> mejora el retorno de la inversión en publicidad mediante una gestión más rápida y eficaz</li>
              <li><i className='fa fa-check text-primary'></i><strong className='text-primary'>Headless:</strong> desacopla el frontend y gana flexibilidad en tu diseño</li>
              <li><i className='fa fa-check text-primary'></i><strong className='text-primary'>Confianza:</strong> soporte 24/7 y certificados GDPR y PCI incluidos en el precio</li>
              <li><i className='fa fa-check text-primary'></i><strong className='text-primary'>Escalable:</strong> se adapta al crecimiento e internacionalización de tu negocio.</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}
