import React from 'react';

import LayoutEs from '../components/LayoutEs';
import Scroll from '../components/Scroll';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Contact from '../components/Contact';
import SEO from '../components/Seo';
import Intro from '../components/Bigcommerce/Intro';
import Calendly from '../components/Calendly';
import { navigate } from 'gatsby';
import ScrollToTop from '../components/ScrollToTop';
import Beneficios from '../components/Bigcommerce/Beneficios';
import Gartner from '../components/Bigcommerce/Gartner';
import Servicios from '../components/Bigcommerce/Servicios';
import Partner from '../components/Bigcommerce/Partner';

class AgenciaPartnerBigcommerce extends React.Component {
  render() {
    return (
      <LayoutEs>
        <Header />
        <SEO
          title="Agencia partner BigCommerce España"
          description="Somos agencia certificada BigCommerce. Desarrollamos tu tienda online a medida y en el menor tiempo posible. 
          Descubre nuestros casos de éxito en proyectos B2B y B2C"
          canonicalUrl="https://www.bekome.digital/agencia-partner-bigcommerce/"
        />

        <header className="masthead secondary bigcommerce d-flex">
          <div className="container text-center mx-auto">
            <h1 className="mb-1 text-primary">Tienda online BigCommerce</h1>
            <h2 className="mb-3">
              Máxima flexibilidad menos preocupaciones
            </h2>
            <p className='h2 mb-5'>
                La solución open SaaS que ofrece mayor <strong className='text-primary'>escalabilidad</strong> a tu negocio 
                sin perder capacidad de <strong className='text-primary'>personalización</strong> ni funcionalidades.
            </p>
            <button className='btn btn-primary-green btn-lg' onClick={() => {navigate("/contacto/")}}>Me interesa</button>
          </div>
          <div className="overlay"></div>
        </header>

        <Intro />

        <Beneficios />

        <Servicios />

        <Partner />

        <div className='container-fluid bg-grey'>
          <div className="container text-center text-white">
            <div className="row">
              <div className="col-12 text-secondary">
                <h4 className="font-size__l mt-5">
                  ¿Estás preparado para modernizar tu eCommerce?
                </h4>
                <p className='font-size__m'>
                    Juntos encotraremos la manera más eficiente de optimizar los recursos de tu eCommerce e incrementar 
                    los beneficios de tu negocio.
                </p>
                <p className='font-size__m mb-5'>
                    Rellena este formulario para más información, y te reponderemos en 24h laborables.
                </p>
              </div>
            </div>
          </div>
        </div>

        <Contact />

        <Footer />

        <ScrollToTop />

      </LayoutEs>
    );
  }
}

export default AgenciaPartnerBigcommerce;
