import React from 'react';

export default function Intro() {
  return (
    <section className="content-section intro-section" id="start">
      <div className="container">
        <div className="row mt-lg-5">
          <div className="col-12">
            <h2 className="font-size__xl mb-5 text-secondary">
              Optimiza tus recursos y dispara la conversión.
            </h2>
            <p className="font-size__m">
              Costes de plataforma, mantenimiento, seguridad... ¿Te has fijado en que la mayoría de recursos que destinas 
              a tu tienda online se consumen en su infraestructura? Estamos de acuerdo, el soporte es importante pero...
            </p>
            <p className="font-size__m">
              ¿Y si le damos la vuelta a la tortilla?
            </p>
            <p className="font-size__m">
              Trabajar con una plataforma Saas, como BigCommerce, <strong>reduce entre 4 y 6 veces los costes de infraestructura </strong> 
              y permite destinar más recursos al desarrollo y la búsqueda de negocio, que son los que más impacto causan 
              en la conversión y el aumento de ventas.
            </p>
            <p className="font-size__m">
              Además, al tratarse de una plataforma Open Saas, <strong>BigCommerce ofrece más flexibilidad</strong> que el resto de 
              plataformas en la nube y permite una mayor personalización y desarrollo de funcionalidades.
            </p>
            <p className="font-size__m">
              En definitiva, podríamos decir que BigCommerce es capaz de ofrecer las mismas prestaciones que una plataforma 
              on premise sin perder las ventajas y agilidad de trabajar con una plataforma Saas.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
