import React from 'react';
import { navigate } from "gatsby"

export default function Intro() {
  return (
    <section className="content-section bg-grey-dark" id="start">
      <div className="container">
        <div className="row mt-lg-5">
          <div className="col-12">
            <h2 className="font-size__xl mb-5 text-secondary text-center">
              Tu partner oficial de BigCommerce
            </h2>
            <p className="font-size__m">
                En Beköme Digital contamos con una larga experiencia como desarrolladores eCommerce, tanto en proyectos B2B como B2C. 
                Hemos trabajado en proyectos de diferentes tamaños y complejidades, lo cual nos ha permitido ampliar 
                nuestros conocimientos y capacidades.
            </p>
            <p className="font-size__m">
                <strong>Nuestro objetivo:</strong> ofrecer soluciones de calidad con la mejor tecnología del momento.
            </p>
            <p className="font-size__m">
                Esto nos ha llevado a convertirnos en especialistas certificados por Magento y, más recientemente, a 
                obtener la certificación como <strong>partner oficial de BigCommerce</strong>.
            </p>
            <p className="font-size__m">
                Porque los tiempos cambian, y saber adaptarse a ellos puede convertirse en tu mayor ventaja competitiva.
            </p>
            <div className='text-center'>
                <button className='btn btn-secondary-blue btn-lg' onClick={() => {navigate("/nosotros/")}}>¿Por qué con nosotros?</button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
