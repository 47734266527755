import React from 'react';
import { navigate } from "gatsby"

export default function Servicios() {
    return (
        <section
            className="content-section servicios-section"
            id="servicios"
        >
            <div className="container text-center text-secondary">
                <div className='row'>
                    <div className='col-12'>
                        <h2 className="font-size__xl mb-5">
                            Nuestros servicios BigCommerce
                        </h2>
                    </div>
                    <div className='col-xs-12 col-lg-3'>
                        <div className="service-block py-5">
                            <i class="fa-solid fa-terminal text-primary"></i>
                            <h3 className="font-size__m mt-5 mb-3">
                                Desarrollo a medida
                            </h3>
                            <p className='mb-3'>
                                Somos especialistas en desarrollo, sobretodo en proyectos B2B. <strong>Desarrollamos tu tienda online BigCommerce headless a medida</strong>.
                            </p>
                        </div>
                    </div>
                    <div className='col-xs-12 col-lg-3'>
                        <div className="service-block py-5">
                            <i class="fa-brands fa-figma text-primary"></i>
                            <h3 className="font-size__m mt-5 mb-3">
                                Diseño a medida
                            </h3>
                            <p className='mb-3'>
                                Con o sin plantillas, hacemos un <strong>diseño a medida para tu tienda online</strong> con el que sorprender a 
                                tus clientes. Mobile first y con una gran experiencia de usuario.
                            </p>
                        </div>
                    </div>
                    <div className='col-xs-12 col-lg-3'>
                        <div className="service-block py-5">
                            <i class="fa-solid fa-arrows-split-up-and-left text-primary"></i>
                            <h3 className="font-size__m mt-5 mb-3">
                                Migración
                            </h3>
                            <p className='mb-3'>
                                ¿Tu plataforma actual no cumple con tus expectativas?<br />Migra tu plataforma a BigCommerce. 
                                <strong>Te ayudaremos con todo el proceso.</strong>
                            </p>
                        </div>
                    </div>
                    <div className='col-xs-12 col-lg-3'>
                        <div className="service-block py-5">
                            <i class="fa-solid fa-arrows-to-circle text-primary"></i>
                            <h3 className="font-size__m mt-5 mb-3">
                                Integraciones
                            </h3>
                            <p className='mb-3'>
                                Catálogo, pedidos, clientes, stock...<br />Nos encargamos de <strong>integrar BigCommerce</strong>
                                 con tus sistemas de ERP, PIM y CRM.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
